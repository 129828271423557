<!-- =========================================================================================
  File Name: Role.vue
  Description: Role Edit Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>

    <b-alert warning="danger" :active.sync="id_not_found">
      <h4 class="alert-heading">
        Rol no encontrado
      </h4>
      <div class="alert-body">
        Rol con id {{ $route.params.id }} no encontrado.
        <span>
          <span>Verifique </span><router-link :to="{name:'roles'}" class="text-inherit underline"> todos los roles</router-link>
        </span>
      </div>
    </b-alert>

    <b-card v-show="loaded" class="mb-2">
      <b-container class="px-6 pt-6">
        <div class="d-flex justify-content-between mb-2">
          <div v-if="editing" class="d-flex flex-row w-25">
            <!--            <ejs-textbox v-model="data_local.role" class="e-input" cssClass="e-code" type="text" floatLabelType="Auto" placeholder="Código" :enabled="editing"/>-->
            <b-form-input v-model="data_local.role" size="lg" placeholder="Escriba aquí el código" :plaintext="!editing" :state="data_local.role.length > 0" required trim/>
          </div>
          <div v-else class="d-flex flex-row w-25 bg-light rounded-sm px-1">
            <div class="flex flex-row">
              <span class="small text-black-50">Código</span>
              <h3 class="font-weight-bolder">{{ data_local.role }}</h3>
            </div>
          </div>
          <span class="flex-grow-1"></span>
          <div v-if="editing" class="d-flex flex-row bg-primary rounded-sm text-white cursor-pointer p-1 justify-content-between" style="width: 200px; margin-right: 1px;" @click="save">
            <feather-icon icon="SaveIcon" size="20"/>
            <div>Guardar cambios</div>
            <div/>
          </div>
          <div v-if="editing" class="d-flex flex-row border border-warning rounded-sm cursor-pointer p-1 justify-content-between" style="width: 150px;" @click="reset_data">
            <feather-icon icon="CornerUpLeftIcon" size="20"/>
            <div>Cancelar</div>
            <div/>
          </div>
          <div v-if="!editing" class="d-flex flex-row bg-primary rounded-sm text-white cursor-pointer p-1 justify-content-between" style="width: 150px; margin-right: 1px;" @click="editing = true">
            <feather-icon icon="Edit2Icon" size="20"/>
            <div>Editar</div>
            <div/>
          </div>
          <div v-if="!editing" class="d-flex flex-row bg-danger rounded-sm text-white cursor-pointer p-1 justify-content-between" style="width: 150px;" @click="confirmDeleteRecord">
            <feather-icon icon="XIcon" size="20"/>
            <div>Eliminar</div>
            <div/>
          </div>
        </div>
        <b-row>
          <b-col>
            <ejs-textbox v-model="data_local.description" class="e-input" type="text" floatLabelType="Auto" placeholder="Descripción" :enabled="editing"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <ejs-treegrid
              ref="options"
              :dataSource="options"
              :treeColumnIndex="1"
              locale='es-GT'
              :toolbar='toolbarOptions'
              height="400px"
              childMapping="options"
              :recordDoubleClick="showPermissions"
              class="mt-1"
            >
              <e-columns>
                <e-column field="id" :visible="false" :isPrimaryKey="true"/>
                <e-column field="title" headerText="Acceso a opciones de menú"/>
                <e-column field="permissions" headerText="Permisos asignados" :template="cTemplate" permissions="permissions"/>
              </e-columns>
            </ejs-treegrid>
          </b-col>
        </b-row>
      </b-container>
    </b-card>

    <b-modal
      v-model="isPermissionsActive"
      centered
      header-bg-variant="primary"
      footer-bg-variant="light"
      ok-title="Guardar"
      cancel-title="Cancelar"
      @ok="savePermissions"
    >
      <!--      <template #modal-title>-->
      <!--        Permisos para opción {{ optionName }}-->
      <!--      </template>-->
      <template #modal-header>
        <h3 class="text-white">Permisos para opción {{ optionName }}</h3>
      </template>
      <div>
        <ejs-listview ref="permissions" :dataSource='permissionsOption' showCheckBox='true'></ejs-listview>
      </div>
    </b-modal>

  </div>
</template>

<script>
import Vue from "vue";
import {L10n, setCulture} from '@syncfusion/ej2-base';
import {BCard, BAlert, BContainer, BRow, BCol, BFormInput} from 'bootstrap-vue';
import {TextBoxPlugin} from '@syncfusion/ej2-vue-inputs';
import {TreeGridPlugin, Toolbar, Filter} from '@syncfusion/ej2-vue-treegrid';
import {ListViewPlugin} from '@syncfusion/ej2-vue-lists';
import CellRendererPermissions from '@/views/components/CRPermissions';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

setCulture('es-GT');
L10n.load({
  'es-GT': {
    treegrid: {
      EmptyRecord: 'No se encontraron opciones',
      Search: 'Buscar',
      Excelexport: 'Exportar'
    }
  }
});

Vue.use(TextBoxPlugin);
Vue.use(TreeGridPlugin);
Vue.use(ListViewPlugin);

const timeout = Vue.$timeout1;

export default {
  components: {
    BCard,
    BAlert,
    BContainer,
    BRow,
    BCol,
    BFormInput
  },
  data() {
    return {
      id: 0,
      loading: null,
      loaded: false,
      editing: false,
      status: [],
      data: null,
      isPermissionsActive: false,
      permissions: [],
      permissionsOption: [],
      options: [],
      statusFields: {
        text: 'value',
        value: 'code'
      },
      data_local: {
        id: 0,
        role: '',
        description: ''
      },
      id_not_found: false,
      fields: {
        dataSource: this.options,
        id: 'id',
        parentID: 'parentId',
        text: 'title',
        hasChildren: 'hasChildren'
      },
      toolbarOptions: ['Search'],
      optionName: '',
      optionId: 0,
      cTemplate() {
        return {template: CellRendererPermissions};
      }
    };
  },
  provide: {
    treegrid: [Toolbar, Filter]
  },
  computed: {
    config() {
      return this.$store.state.fastway.parameters;
    },
    api() {
      return this.$store.state.fastway.parameters.apiURL;
    }
  },
  mounted() {
    const self = this;
    this.id = parseInt(this.$route.params.id, 10);
    this.loading = this.$loading.show();
    this.$http
      .post(`${this.api}/users/permissions/list`, {
        userId: this.$store.state.fastway.userInfo.userId,
        token: this.$store.state.fastway.userInfo.token
      })
      .then(response => {
        if (response.data.success) {
          self.permissions = response.data.data;
          // for (var i = 0; i < response.data.data.length; i++) {
          //   self.permissions.push(response.data.data[i].code);
          // }
        }
        self.getData(self.id);
      })
      .catch(error => {
        self.loading.hide();
        self.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al actualizar',
            text: error.message,
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        }, {timeout});
      });
  },
  methods: {
    reset_data() {
      if (this.id > 0) {
        this.loadOptions(this.data);
        this.editing = false;
      } else {
        this.$router.push({name: 'roles'});
      }
    },
    pushOption(option) {
      const options = [];
      if (option.children != null) {
        for (let i = 0; i < option.children.length; i++) {
          options.push(this.pushOption(option.children[i]));
        }
      }
      return {
        id: option.id,
        title: option.title,
        slug: option.slug,
        validPermissions: option.validPermissions,
        permissions: option.permissions,
        options
      };
    },
    loadOptions(data) {
      this.data_local = JSON.parse(JSON.stringify(data));
      this.options = [];
      for (let i = 0; i < data.options.length; i++) {
        if (data.options[i].parentId == 0) {
          this.options.push(this.pushOption(data.options[i]));
        }
      }
    },
    getData(id) {
      if (id) {
        if (id == 0) {
          this.loading.hide();
          this.loaded = true;
          this.id_not_found = false;
          this.editing = true;
        } else {
          const self = this;
          this.$http.post(`${this.config.apiURL}/roles/data`, {
            id,
            context: this.$store.state.fastway.userInfo
          })
            .then(response => {
              if (response.data.success) {
                self.data = response.data.data;
                self.loadOptions(response.data.data);
              } else if (id > 0) {
                self.id_not_found = true;
              }
              self.loading.hide();
              self.loaded = true;
            });
        }
      } else {
        this.loading.hide();
        this.loaded = true;
        if (id == 0) {
          this.id_not_found = false;
        }
        this.editing = true;
      }
    },
    getOptionPermissions(id, options) {
      let permissions = '';
      for (let i = 0; i < options.length; i++) {
        if (permissions == '') {
          if (options[i].id == id) {
            permissions = options[i].permissions;
          } else if (options[i].hasChildren) {
            permissions = this.getOptionPermissions(id, options[i].children);
          }
        }
      }
      return permissions;
    },
    setOptionPermissions(id, options, permissions) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].id == id) {
          options[i].permissions = permissions;
        } else if (options[i].children) {
          this.setOptionPermissions(id, options[i].children, permissions);
        }
      }
    },
    showPermissions(row) {
      if (this.editing) {
        this.optionId = row.rowData.id;
        const self = this;
        const permissions = this.getOptionPermissions(this.optionId, this.data_local.options);
        this.optionName = row.rowData.title;
        this.permissionsOption = [];
        this.permissions.forEach(item => {
          if (row.rowData.validPermissions.includes(item.code)) {
            self.permissionsOption.push({
              text: item.name,
              id: item.code,
              isChecked: permissions.includes(item.code)
            });
          }
        });
        this.isPermissionsActive = true;
      }
    },
    savePermissions() {
      let permissions = '';
      this.$refs.permissions.getSelectedItems()
        .data
        .forEach(item => {
          permissions += (permissions == '' ? '' : ',') + item.id;
        });
      if (!permissions.includes('ACC')) {
        permissions = '';
      }
      this.setOptionPermissions(this.optionId, this.data_local.options, permissions);
      this.$refs.options.setCellValue(this.optionId, 'permissions', permissions);
      this.$refs.options.refresh();
      this.isPermissionsActive = false;
    },
    validateForm() {
      let message = '';
      if (!this.data_local.role || this.data_local.role == '') {
        message = 'Debe especificar un código';
      } else if (!this.data_local.description || this.data_local.description == '') {
        message = 'Debe especificar una descripción';
      }
      if (message != '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al guardar rol',
            text: message,
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        }, {timeout});
      }
      return (message == '');
    },
    save() {
      if (this.validateForm()) {
        this.loading = this.$loading.show();
        const self = this;
        this.data_local.id = this.id;
        this.data_local.context = this.$store.state.fastway.userInfo;
        this.data_local.createdBy = this.$store.state.fastway.userInfo.userId;
        this.$http.post(`${this.api}/roles/save`, this.data_local)
          .then(response => {
            if (response.data.success) {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Información',
                  text: 'Datos han sido actualizados',
                  icon: 'InfoIcon',
                  variant: 'success'
                }
              });
              if (self.id == 0) {
                self.$router.push({
                  name: 'role',
                  params: {id: response.data.data.id}
                });
              }
            } else {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error al actualizar',
                  text: response.data.message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger'
                }
              }, {timeout});
            }
            self.editing = false;
            self.loading.hide();
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error al actualizar',
                text: error.message,
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            }, {timeout});
          });
      }
    },
    confirmDeleteRecord() {
      const self = this;
      this.$bvModal.msgBoxConfirm(`Se dispone a eliminar rol "${this.data.role}"`, {
        title: `Confirmar`,
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Aceptar',
        cancelTitle: 'Cancelar',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true
      })
        .then(proceed => {
          if (proceed) {
            self.loading = this.$loading.show();
            const data = {
              objectId: self.id,
              userId: self.$store.state.fastway.userInfo.userId,
              token: self.$store.state.fastway.userInfo.token
            };
            self.$http.post(`${this.api}/roles/delete`, data)
              .then(response => {
                if (response.data.success) {
                  self.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Información eliminada',
                      text: 'Rol fue eliminado correctamente',
                      icon: 'InfoIcon',
                      variant: 'success'
                    }
                  });
                  self.$router.push({name: 'roles'});
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error al eliminar',
                      text: response.data.message,
                      icon: 'AlertCircleIcon',
                      variant: 'danger'
                    }
                  }, {timeout});
                }
                this.editing = false;
                this.loading.hide();
              });
          }
        });
    }
  }
};
</script>

<style lang="scss">
@import '../../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../../../../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-treegrid/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-lists/styles/material.css";

.e.input .e-code {
  font-weight: bolder;
  font-size: large;
}

.wrapper {
  max-width: 250px;
  margin: 0 auto;
}

.e-grid .e-altrow {
  background-color: #fafafa;
}

#avatar-col {
  width: 12rem;
}

#page-stock-role-edit {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .4rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }
}

@media screen and (min-width: 1201px) and (max-width: 1211px),
only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }
}

</style>
