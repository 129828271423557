<!-- =========================================================================================
  File Name: Users.vue
  Description: Users' list
  ----------------------------------------------------------------------------------------
  Item Name: FastWay - Users' list for management
  Author: Oscar Ramírez O.
  Author URL: https://acentoNET.com
========================================================================================== -->

<template>

  <div>
    <!--    :to="{name: 'user', params: { id: 0 }}-->
    <b-button variant="success" class="btn-icon rounded-circle add-button" @click="$router.push({name: 'user', params: {id: 0}})">
      <feather-icon icon="PlusIcon"/>
    </b-button>

    <b-card class="p-6">
      <ejs-grid
        v-if="loaded"
        ref="grid"
        :dataSource="data"
        locale='es-GT'
        :toolbar='toolbarOptions'
        :allowPaging="true"
        :pageSettings='pageSettings'
        :allowSorting='true'
        :sortSettings='sortOptions'
        :allowExcelExport='true'
        :toolbarClick='toolbarClick'
        :allowFiltering='true'
        :filterSettings='filterOptions'
      >
        <e-columns>
          <e-column field="id" :visible=false headerText="ID" :isPrimaryKey="true"/>
          <e-column field='user' headerText='Usuario' width="300" :template="cTemplate" displayValue="user" routeName="user" urlBase="/infra/user/"/>
          <e-column field="name" headerText="Nombre" :template="cTemplate" displayValue="name" routeName="user" urlBase="/infra/user/"/>
          <e-column field="branchName" headerText="Empresa" width="200" :filter='checkBoxFilter'/>
          <e-column field="statusName" headerText="Estado" width="140" :filter='checkBoxFilter' :template='cTemplateStatus'/>
        </e-columns>
      </ejs-grid>
    </b-card>
  </div>

</template>

<script>
import Vue from 'vue';
import {BCard, BButton} from 'bootstrap-vue';
import {loadCldr, L10n, setCulture} from '@syncfusion/ej2-base';
import {GridPlugin, Toolbar, Search, Page, Sort, ExcelExport, Filter} from '@syncfusion/ej2-vue-grids';
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import gregorian from 'cldr-data/main/es-GT/ca-gregorian.json';
import numbers from 'cldr-data/main/es-GT/numbers.json';
import timeZoneNames from 'cldr-data/main/es-GT/timeZoneNames.json';
import weekData from 'cldr-data/supplemental/weekData.json';
import CellRendererLink from '@/views/components/CRLinkS';
import CellStatus from '@/views/components/CRStatus';

Vue.use(GridPlugin);

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
setCulture('es-GT');
L10n.load({
  'es-GT': {
    grid: {
      EmptyRecord: 'No se encontraron datos',
      Search: 'Buscar',
      Excelexport: 'Exportar'
    },
    pager: {
      currentPageInfo: '{0} de {1} páginas',
      firstPageTooltip: 'Ir a primera página',
      lastPageTooltip: 'Ir a última página',
      nextPageTooltip: 'Ir a página siguiente',
      previousPageTooltip: 'Ir a página anterior'
    }
  }
});

export default {
  components: {
    BCard,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    CellRendererLink
  },
  data() {
    return {
      pageSettings: {pageSize: 12},
      toolbarOptions: [{
        tooltipText: 'Recargar',
        prefixIcon: 'e-refresh',
        id: 'reloadData'
      }, 'ExcelExport', 'Search'],
      sortOptions: {
        columns: [{
          field: 'user',
          direction: 'Ascending'
        }]
      },
      statusId: null,
      loaded: false,
      data: [],
      cTemplate() {
        return {
          template: CellRendererLink,
          urlBase: '/infra/user/'
        };
      },
      cTemplateStatus() {
        return {template: CellStatus};
      },
      filterOptions: {
        type: 'Menu'
      },
      checkBoxFilter: {
        type: 'CheckBox'
      }
    };
  },
  provide: {
    grid: [Toolbar, Search, Page, Sort, ExcelExport, Filter]
  },
  computed: {
    config() {
      return this.$store.state.fastway.parameters;
    },
    api() {
      return this.$store.state.fastway.parameters.apiURL;
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    toolbarClick(args) {
      if (args.item.id.includes('excelexport')) {
        const excelExportProperties = {
          fileName: "ListadoUsers.xlsx"
        };
        this.$refs.grid.excelExport(excelExportProperties);
      } else if (args.item.id == 'reloadData') {
        this.getData();
      }
    },
    getData() {
      const loading = this.$loading.show();
      const self = this;
      this.$http.post(`${this.config.apiURL}/users/list`, this.$store.state.fastway.userInfo)
        .then(response => {
          if (response.data.success) {
            if (response.data.data) {
              self.data = response.data.data;
            }
          }
          // this.$vs.loading.close();
          loading.hide();
          self.loaded = true;
        })
        .catch(() => {
          self.loaded = true;
          loading.hide();
          // let errorMessage = '';
          // if (error.response) {
          //   errorMessage = error.response.data.ErrorMessage
          // } else {
          //   errorMessage = 'Error de conectividad'
          // }
          // self.$vs.notify({
          //   title: 'Error al cargar listado de parámetros',
          //   text: errorMessage,
          //   color: 'danger'
          // });
        });
    },
    edit(id) {
      this.$router.push(`/infra/user/value/${id}`);
    }
  }
};

</script>

<style lang="scss">
@import '../../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import "../../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import '../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';

.add-button {
  position: fixed;
  bottom: 5%;
  right: 29px;
  z-index: 51000;
  box-shadow: 0 1px 20px 1px rgb(251, 67, 79);
}

.e-toolbar-item .e-refresh::before {
  font: normal normal normal 16px/1 FontAwesome;
  content: "\f021";
  color: green;
}
</style>
