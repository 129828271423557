<template>
  <div>
    <b-card
      border-variant="primary"
      header="Carga de informe"
      header-bg-variant="primary"
      header-text-variant="white"
      align="center"
    >
      <b-card-text class="p-1">
        En el cuadro inferior debe colocar el archivo que desea cargar.<br/>
        <br/>
        <div class="container">
          <form enctype="multipart/form-data" novalidate class="row justify-content-center">
            <div class="col"/>
            <div class="row col-7 dropbox">
              <input ref="inputFile" type="file" :name="uploadFieldName" :disabled="isSaving" accept="*/*" class="input-file" @change="filesChange">
              <div v-if="isInitial" class="row w-100 align-items-center">
                <div class="col text-center">
                  Arrastre archivo a este cuadro o haga click para seleccionar
                </div>
              </div>
              <div v-else class="w-100 align-items-center p-1">
                <div v-for="file in sortedFiles" :key="file.name" class="row">
                  <b class="w-100 text-left mb-2">{{ file.name }}</b>
                  <span class="w-100 text-left">{{ file.description }}</span>
                </div>
              </div>
            </div>
            <div class="col"/>
          </form>
        </div>
        <b-form-input
          v-if="isSuccess"
          v-model="fileDescription"
          class="my-1"
          :state="descriptionState"
          placeholder="Escriba una descripción para el archivo"
          trim
          :input="updateDescription"
        />
        <b-button v-if="isSuccess && fileDescription.length > 5" class="mt-2" variant="primary" @click="loadFile">
          <feather-icon icon="UploadIcon"/>
          Cargar archivo
        </b-button>
      </b-card-text>
    </b-card>

    <b-card
      v-if="uploads.length > 0"
      border-variant="primary"
      header="Cargas anteriores"
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <div class="row row-cols-3">
        <div v-for="(upload, index) in uploads" :key="index">
          <b-card
            :border-variant="backgroundTitle(upload)"
            :header="uploadTitle(upload)"
            :header-bg-variant="backgroundTitle(upload)"
            header-text-variant="white"
            class="m-1"
            footer-tag="footer"
          >
            <div class="w-100 mt-1">
              <a :href="`https://firebasestorage.googleapis.com/v0/b/agis-perfiles.appspot.com/o/reports%2F${upload.fileName}?alt=media`" target="_blank">{{ upload.fileName }}</a>
            </div>
            <template #footer>
              <b-button variant="warning" @click="deleteFile(upload)">
                <feather-icon icon="DeleteIcon" class="mr-1"/>
                Eliminar archivo
              </b-button>
            </template>
          </b-card>
        </div>
      </div>

    </b-card>
  </div>
</template>

<script>
import Vue from 'vue';
import {BCard, BCardText, BButton, BFormInput} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {db, storage} from '@/firebaseConfig';

const STATUS_INITIAL = 0;
const STATUS_SAVING = 1;
const STATUS_SUCCESS = 2;
const STATUS_FAILED = 3;

const timeout = Vue.$timeout1;

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BFormInput
  },
  data() {
    return {
      loading: null,
      uploadFiles: [],
      currentStatus: null,
      uploadFieldName: 'photos',
      fileDescription: ''
    };
  },
  computed: {
    api() {
      return this.$store.state.fastway.parameters.apiURL;
    },
    userInfo() {
      return this.$store.state.fastway.userInfo;
    },
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    uploads() {
      return this.$store.state.fastway.reports;
    },
    processing() {
      let process = 1;
      if (this.uploads) {
        if (this.uploads.records > this.uploads.processed || !this.uploads.finished) {
          process = 2;
        }
        if (this.uploads.records > 0 && this.uploads.processed >= this.uploads.records && this.uploads.endDate == null) {
          db.collection('uploads')
            .doc(this.userInfo.currentUpload)
            .update({endDate: new Date()});
        }
      }
      return process;
    },
    processed() {
      return this.uploads.processed > this.uploads.records ? this.uploads.records : this.uploads.processed;
    },
    sortedFiles() {
      return [...this.uploadFiles].sort((a, b) => {
        let ret = 1;
        if (a.sequence < b.sequence) {
          ret = -1;
        }
        return ret;
      });
    },
    descriptionState() {
      return (this.fileDescription.length > 5);
    }
  },
  mounted() {
    this.reset();
  },
  methods: {
    reset() {
      this.uploadFiles = [];
      this.currentStatus = STATUS_INITIAL;
      if (this.$refs.inputFile) {
        this.$refs.inputFile.value = '';
      }
    },
    uploadTitle(upload) {
      return upload.description;
    },
    backgroundTitle(upload) {
      let color = 'success';
      if (Object.prototype.hasOwnProperty.call(upload, 'status')) {
        if (upload.status == 'IN') {
          color = 'warning';
        } else if (upload.status == 'ERR') {
          color = 'danger';
        }
      }
      return color;
    },
    updateDescription(value) {
      if (this.uploadFiles.length > 0) {
        this.uploadFiles[0].description = value;
      }
    },
    uploadStatus(upload) {
      let status = 'Finalizado';
      if (Object.prototype.hasOwnProperty.call(upload, 'status')) {
        status = upload.status;
      }
      return status;
    },
    filesChange(event) {
      if (event) event.preventDefault();
      const {files} = event.target;
      if (files !== undefined) {
        this.uploadFiles = [];
        for (let i = 0; i < files.length; i++) {
          const fileName = files[i].name;
          this.uploadFiles.push({
            name: fileName,
            description: ''
          });
          this.currentStatus = STATUS_SUCCESS;
        }
      } else {
        this.reset();
      }
    },
    loadFile() {
      this.loading = this.$loading.show();
      const self = this;
      const file = self.$refs.inputFile.files[0];
      db.collection('reports')
        .add({
          userId: this.userInfo.uid,
          userName: this.userInfo.displayName,
          branchId: this.userInfo.branchId,
          token: this.userInfo.token,
          uploadDate: new Date(),
          description: this.fileDescription,
          fileName: file.name,
          status: 'ACT'
        })
        .then(() => {
          const imageRef = storage.ref()
            .child(`reports/${file.name}`);
          imageRef.put(file)
            .then(() => {
              self.loading.hide();
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Información actualizada',
                  text: 'Archivo fue cargado correctamente',
                  icon: 'InfoIcon',
                  variant: 'success'
                }
              }, {timeout});
              self.reset();
            })
            .catch(error => {
              self.loading.hide();
              self.$vs.notify({
                title: 'ADVERTENCIA',
                text: `Datos del elemento de carrusel fueron actualizados correctamente pero ocurrió un error al actualizar imagen: ${error.message}`,
                color: 'warning'
              });
            });
        })
        .catch(error => {
          self.loading.hide();
          self.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error al cargar archivo',
              text: error.message,
              icon: 'AlertCircleIcon',
              variant: 'danger'
            }
          }, {timeout});
        });
    },
    deleteFile(file) {
      const self = this;
      this.$bvModal.msgBoxConfirm(`¿Está seguro de eliminar el archivo ${file.fileName}?`, {
        title: 'Confirme',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'SI',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(deleteFile => {
          if (deleteFile) {
            db.collection('reports')
              .doc(file.id)
              .update({status: 'IN'})
              .then(() => {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Información actualizada',
                    text: 'Archivo fue eliminado',
                    icon: 'InfoIcon',
                    variant: 'success'
                  }
                }, {timeout});
              })
              .catch(error => {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error al eliminar archivo',
                    text: error.message,
                    icon: 'AlertCircleIcon',
                    variant: 'danger'
                  }
                }, {timeout});
              });
          }
        })
        .catch(() => {
          // An error occurred
        });
    }
  }
};
</script>
<style>
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  width: 700px;
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
  z-index: 100;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
