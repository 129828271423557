<template>
  <div>
    <b-card title="AGIS">
      <b-card-text>Seleccione una opción en el menú a la izquierda.</b-card-text>
    </b-card>
  </div>
</template>

<script>
import {BCard, BCardText} from 'bootstrap-vue';

export default {
  components: {
    BCard,
    BCardText
  }
};
</script>

<style>

</style>
