<template>
  <div class="flex items-center">
    <!--    <vs-chip v-for="item in permissions" :key="item" :color="color(item)">{{ item }}</vs-chip>-->
    <ejs-chiplist v-for="item in permissions" :key="item" :text="item" :cssClass="color(item)"></ejs-chiplist>
  </div>
</template>

<script>
import Vue from 'vue';
import {ChipListPlugin} from '@syncfusion/ej2-vue-buttons';

Vue.use(ChipListPlugin);

export default {
  name: 'CellRendererPermissions',
  computed: {
    permissions() {
      return (this.$data.data[this.$data.data.column.permissions] ? this.$data.data[this.$data.data.column.permissions].split(',') : []);
    }
  },
  methods: {
    color(item) {
      let color = 'e-info';
      if (item == 'ACC') {
        color = 'e-success';
      } else if (item == 'IMP') {
        color = 'e-warning';
      } else if ('ELI|ANU'.includes(item)) {
        color = 'e-danger';
      }
      return color;/**/
    }
  }
};
</script>
