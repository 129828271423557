<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">
          <img src="@/assets/images/logo/logo.png" width="70" alt="AGIS"/>
          AGIS
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Bienvenido a AGIS
          </b-card-title>
          <b-card-text class="mb-2">
            Por favor ingrese con sus credenciales asignadas
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Usuario"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Usuario"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userId"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="Usuario asignado"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Clave</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                v-if="config"
                type="submit"
                variant="primary"
                block
                :disabled="loginDisabled"
                @click="validationForm"
              >
                Acceder
              </b-button>
            </b-form>
          </validation-observer>

        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {db, firebase} from '@/firebaseConfig';
import {ValidationProvider, ValidationObserver, localize} from 'vee-validate';
import es from 'vee-validate/dist/locale/es.json';
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton
} from 'bootstrap-vue';
import {required, email} from '@validations';
import {togglePasswordVisibility} from '@core/mixins/ui/forms';
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import localForage from 'localforage';

localize('es', es);
// ValidationProvider.localize('es');

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loginDisabled: false,
      status: '',
      password: '',
      userId: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email
    };
  },
  computed: {
    config() {
      return store.state.fastway.parameters;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    }
  },
  methods: {
    getMenu(menu) {
      for (let i = 0; i < menu.length; i++) {
        if (menu[i].options) {
          if (menu[i].options.length > 0) {
            menu[i].submenu = this.getMenu(menu[i].options);
          }
        }
      }
      return menu;
    },
    validationForm() {
      this.loginDisabled = true;
      this.$refs.loginValidation.validate()
        .then(success => {
          const self = this;
          const loginData = {
            login: this.userId,
            password: this.password,
            loginDisabled: false
          };
          if (success) {
            this.$store.dispatch('fastway/updateNavMenuItems', []);
            this.$http.post(`${this.config.apiURL}/users/login`, loginData)
              .then(response => {
                if (response.data.success) {
                  if (response.data.data.token == '') {
                    const timeout = 7000;
                    self.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Acceso denegado',
                        text: 'Usuario o clave incorrecta',
                        icon: 'AlertCircleIcon',
                        variant: 'danger'
                      }
                    }, {timeout});
                  } else {
                    const userInfo = {
                      uid: response.data.data.id,
                      userId: response.data.data.id,
                      login: response.data.data.login,
                      userName: response.data.data.login,
                      displayName: response.data.data.name,
                      branchId: response.data.data.branchId,
                      branchName: response.data.data.branchName,
                      token: response.data.data.token
                    };
                    db.collection('sessions')
                      .doc(userInfo.token)
                      .set({
                        token: userInfo.token,
                        userId: userInfo.uid,
                        loginDate: firebase.firestore.FieldValue.serverTimestamp(),
                        lastUpdate: firebase.firestore.FieldValue.serverTimestamp(),
                        active: true
                      })
                      .then(() => {
                        if (response.data.data.image != "") {
                          userInfo.photoURL = response.data.data.image;
                        }
                        const {prefix} = self.config;
                        localForage.setItem(`${prefix}UserInfo`, userInfo);
                        localForage.setItem(`${prefix}UserId`, response.data.data.id);
                        localForage.setItem(`${prefix}Login`, response.data.data.login);
                        localForage.setItem(`${prefix}UserName`, response.data.data.name);
                        localForage.setItem(`${prefix}Token`, response.data.data.token);
                        self.$store.dispatch('fastway/updateUserInfo', userInfo);
                        store.dispatch('fastway/bindSession', userInfo.token);
                        self.$http
                          .post(`${self.config.apiURL}/users/options`, {
                            userId: response.data.data.id,
                            token: response.data.data.token
                          })
                          .then(optionsResponse => {
                            if (optionsResponse.data.success) {
                              if (optionsResponse.data.data) {
                                self.$store.dispatch('fastway/updateNavMenuItems', self.getMenu(optionsResponse.data.data));
                              }
                              window.location.assign('/');
                            } else {
                              self.$toast({
                                component: ToastificationContent,
                                props: {
                                  title: 'Error al validar opciones de usuario',
                                  text: optionsResponse.data.message,
                                  icon: 'AlertCircleIcon',
                                  variant: 'danger'
                                }
                              });
                            }
                          })
                          .catch(error => {
                            self.loginDisabled = false;
                            let errorMessage = '';
                            if (error.message) {
                              errorMessage = error.message;
                            } else if (error.response) {
                              errorMessage = error.response.data.ErrorMessage;
                            } else {
                              errorMessage = 'Error de conectividad';
                            }
                            self.$toast({
                              component: ToastificationContent,
                              props: {
                                title: 'Error al validar credenciales',
                                text: errorMessage,
                                icon: 'AlertCircleIcon',
                                variant: 'danger'
                              }
                            });
                          });
                      });
                  }
                }
                self.loginDisabled = false;
              })
              .catch(error => {
                self.loginDisabled = false;
                let errorMessage = '';
                if (error.message) {
                  errorMessage = error.message;
                } else if (error.response) {
                  errorMessage = error.response.data.ErrorMessage;
                } else {
                  errorMessage = 'Error de conectividad';
                }
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error al validar credenciales',
                    text: errorMessage,
                    icon: 'AlertCircleIcon',
                    variant: 'danger'
                  }
                });
              });
          } else {
            this.loginDisabled = false;
          }
        });
    }
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
