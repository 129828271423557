<template>
  <div>
    <b-card>
      <div class="row row-cols-3">
        <div v-for="(upload, index) in uploads" :key="index">
          <b-card
            :border-variant="backgroundTitle(upload)"
            :header="upload.description"
            :header-bg-variant="backgroundTitle(upload)"
            header-text-variant="white"
            class="m-1"
          >
            <div class="w-100 mt-1">
              <a :href="`https://firebasestorage.googleapis.com/v0/b/agis-perfiles.appspot.com/o/reports%2F${upload.fileName}?alt=media`" target="_blank">{{ upload.fileName }}</a>
            </div>
          </b-card>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {BCard} from 'bootstrap-vue';

export default {
  components: {
    BCard
  },
  data() {
    return {
      loading: null
    };
  },
  computed: {
    api() {
      return this.$store.state.fastway.parameters.apiURL;
    },
    userInfo() {
      return this.$store.state.fastway.userInfo;
    },
    uploads() {
      return this.$store.state.fastway.reports;
    }
  },
  methods: {
    backgroundTitle(upload) {
      let color = 'success';
      if (Object.prototype.hasOwnProperty.call(upload, 'status')) {
        if (upload.status == 'IN') {
          color = 'warning';
        } else if (upload.status == 'ERR') {
          color = 'danger';
        }
      }
      return color;
    }
  }
};
</script>
<style>
</style>
