<template>
  <iframe :width="viewPortWidth" :height="viewPortHeight" src="https://app.powerbi.com/view?r=eyJrIjoiM2NlOWI3NmEtNTMxZC00ZmU1LWIyZGYtNDJjMTU5OGQ1MTc1IiwidCI6ImU5MGZjZjA5LTRlOGQtNDU0Mi1hNWM4LTQ2YjM1NzQwZGU0NCJ9" frameborder="0" allowFullScreen="false"></iframe>
</template>

<script>

export default {
  computed: {
    viewPortWidth() {
      return this.$store.state.app.windowWidth - (this.$store.state.verticalMenu.isVerticalMenuCollapsed ? 140 : 320);
    },
    viewPortHeight() {
      return window.innerHeight * 0.8;
    }
  }
};

</script>
