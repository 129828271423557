import firebase from "firebase";
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCgX4vnxG6y9GMaPT07sXgLkmwF8mVaZFA",
  authDomain: "agis-perfiles.firebaseapp.com",
  databaseURL: "https://agis-perfiles.firebaseio.com",
  projectId: "agis-perfiles",
  storageBucket: "agis-perfiles.appspot.com",
  messagingSenderId: "437256489324",
  appId: "1:437256489324:web:1b672645fa4bfaad934b49",
  measurementId: "G-VZYSCC614Q"
};

firebase.initializeApp(firebaseConfig);

// firebase utils
const db = firebase.firestore();
const storage = firebase.storage();
const storageRef = storage.ref();
const auth = firebase.auth();

// firebase collections
// const categories = db.collection('categories');

export {
  firebase,
  db,
  storage,
  storageRef,
  auth
  // categories
};
