import {firestoreAction} from 'vuexfire';
import {db} from '@/firebaseConfig';
import localForage from 'localforage';

export default {
  namespaced: true,
  state: {
    userInfo: {
      id: 0,
      uid: 0,
      userId: 0,
      login: '',
      userName: '',
      displayName: '',
      about: '',
      photoURL: '',
      status: '',
      userRole: '',
      branchId: 0,
      branchName: '',
      token: '',
      currentUpload: ''
    },
    viewParameters: {
      products: {
        pageSize: 15,
        currentPage: 1,
        order: 'code',
        search: ''
      }
    },
    navMenuItems: [],
    parameters: null,
    session: null,
    uploads: [],
    reports: []
  },
  getters: {
    getParameter: state => paramSearch => {
      const result = state.parameters.filter(parameter => parameter.id === paramSearch);
      return result.length ? result.pop() : false;
    }
  },
  mutations: {
    CLEAR_USER_INFO(state) {
      state.userInfo = {
        id: 0,
        uid: 0,
        userId: 0,
        login: '',
        userName: '',
        displayName: '',
        about: '',
        photoURL: '',
        status: '',
        userRole: '',
        branchId: 0,
        branchName: '',
        token: ''
      };
    },
    UPDATE_USER_INFO(state, payload) {
      // Get Data localStorage
      // const userInfo = JSON.parse(localStorage.getItem(`${prefix}UserInfo`)) || state.userInfo;

      const userInfo = localForage.getItem(`${state.parameters.prefix}UserInfo`) || state.userInfo;

      // eslint-disable-next-line no-restricted-syntax
      for (const property of Object.keys(payload)) {
        if (payload[property] != null) {
          // If some of user property is null - user default property defined in state.userInfo
          state.userInfo[property] = payload[property];

          // Update key in localStorage
          userInfo[property] = payload[property];
        }
      }
      // Store data in localStorage
      localForage.setItem(`${state.parameters.prefix}UserInfo`, userInfo);
    },

    UPDATE_PARAMETERS(state, value) {
      state.parameters = value;
    },

    UPDATE_NAVMENUTITEMS(state, value) {
      state.navMenuItems = value;
    },

    UPDATE_VIEWPARAMETERS(state, payload) {
      const connection = state.parameters.filter(parameter => parameter.id === 'connection');
      let prefix = 'AGIS';
      if (connection) {
        if (connection.length > 0) {
          prefix = connection[0].prefix;
        }
      }

      // Get Data localStorage
      const viewParameters = localForage.getItem(`${prefix}ViewParameters`) || state.viewParameters;

      // eslint-disable-next-line no-restricted-syntax
      for (const property of Object.keys(payload)) {
        if (payload[property] != null) {
          state.viewParameters[property] = payload[property];

          // Update key in localStorage
          viewParameters[property] = payload[property];
        }
      }
      // Store data in localStorage
      localForage.setItem(`${prefix}ViewParameters`, (viewParameters));
    }
  },
  actions: {
    bindParameters: firestoreAction(({bindFirestoreRef}) => bindFirestoreRef('parameters', db.collection('parameters'))),

    bindSession: firestoreAction(({bindFirestoreRef}, token) => bindFirestoreRef('session', db.collection('sessions')
      .doc(token))),

    bindReports: firestoreAction(({bindFirestoreRef}) => {
      bindFirestoreRef('reports', db.collection('reports')
        .where('status', '==', 'ACT')
        .orderBy('uploadDate', 'desc')
        .limit(500));
    }),

    bindUploads: firestoreAction(({bindFirestoreRef}, userId) => {
      bindFirestoreRef('uploads', db.collection('uploads')
        .where('userId', '==', userId)
        .orderBy('startDate', 'desc')
        .limit(12));
    }),

    unbindUploads: firestoreAction(({unbindFirestoreRef}) => unbindFirestoreRef('uploads')),

    clearUserInfo({commit}) {
      commit('CLEAR_USER_INFO');
    },

    updateUserInfo({commit}, payload) {
      commit('UPDATE_USER_INFO', payload);
    },

    updateParameters({commit}, value) {
      commit('UPDATE_PARAMETERS', value);
    },

    updateNavMenuItems({commit}, value) {
      commit('UPDATE_NAVMENUTITEMS', value);
    },

    updateViewParameters({commit}, value) {
      commit('UPDATE_VIEWPARAMETERS', value);
    }

  }
};
