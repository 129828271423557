import Vue from 'vue';
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue';
import axios from '@/libs/axios.js';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.component(FeatherIcon.name, FeatherIcon);
Vue.use(Loading, {
  loader: 'dots',
  color: '#0000c0'
});

Vue.prototype.$http = axios;
Vue.prototype.$timeout1 = 7000;
