<!-- =========================================================================================
  File Name: Parameter.vue
  Description: Parameter value Edit Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>

    <b-alert warning="danger" :active.sync="id_not_found">
      <h4 class="alert-heading">
        Parámetro no encontrado
      </h4>
      <div class="alert-body">
        Parámetro con id {{ $route.params.id }} no encontrados.
        <span>
          <span>Verifique </span><router-link :to="{name:'parameters'}" class="text-inherit underline"> todos los parámetros</router-link>
        </span>
      </div>
    </b-alert>

    <b-card v-show="loaded" class="mb-2">
      <b-container class="px-6 pt-6">
        <div class="d-flex justify-content-end">
          <div v-if="editing" class="d-flex flex-row bg-primary rounded-sm text-white cursor-pointer p-1 justify-content-between" style="width: 200px; margin-right: 1px;" @click="save">
            <feather-icon icon="SaveIcon" size="20"/>
            <div>Guardar cambios</div>
            <div/>
          </div>
          <div v-if="editing" class="d-flex flex-row border border-warning rounded-sm cursor-pointer p-1 justify-content-between" style="width: 150px;" @click="reset_data">
            <feather-icon icon="CornerUpLeftIcon" size="20"/>
            <div>Cancelar</div>
            <div/>
          </div>
          <div v-if="!editing" class="d-flex flex-row bg-primary rounded-sm text-white cursor-pointer p-1 justify-content-between" style="width: 150px; margin-right: 1px;" @click="editing = true">
            <feather-icon icon="Edit2Icon" size="20"/>
            <div>Editar</div>
            <div/>
          </div>
          <div v-if="!editing" class="d-flex flex-row bg-danger rounded-sm text-white cursor-pointer p-1 justify-content-between" style="width: 150px;" @click="confirmDeleteRecord">
            <feather-icon icon="XIcon" size="20"/>
            <div>Eliminar</div>
            <div/>
          </div>
        </div>
        <b-row>
          <b-col>
            <div class="w-full">
              <div class="w-full">
                <ejs-textbox v-model="data_local.parameter" class="e-input" type="text" floatLabelType="Auto" placeholder="Parámetro" :enabled="editing && id == 0"/>
              </div>
              <div class="w-full">
                <ejs-textbox v-model="data_local.code" class="e-input" type="text" floatLabelType="Auto" placeholder="Código" :enabled="editing"/>
              </div>
              <div class="w-full mb-4">
                <ejs-textbox v-model="data_local.value" class="e-input" type="text" floatLabelType="Auto" placeholder="Valor" :enabled="editing"/>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import Vue from "vue";
import {BCard, BAlert, BContainer, BRow, BCol} from 'bootstrap-vue';
import {TextBoxPlugin} from '@syncfusion/ej2-vue-inputs';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

Vue.use(TextBoxPlugin);

const timeout = Vue.$timeout1;

export default {
  components: {
    BCard,
    BAlert,
    BContainer,
    BRow,
    BCol
  },
  data() {
    return {
      id: 0,
      loading: null,
      loaded: false,
      editing: false,
      data: null,
      data_local: {
        id: 0,
        parameter: '',
        code: '',
        value: ''
      },
      id_not_found: false
    };
  },
  computed: {
    config() {
      return this.$store.state.fastway.parameters;
    },
    api() {
      return this.$store.state.fastway.parameters.apiURL;
    }
  },
  mounted() {
    this.id = parseInt(this.$route.params.id, 10);
    this.loading = this.$loading.show();
    this.getData(this.id);
  },
  methods: {
    reset_data() {
      if (this.id > 0) {
        this.data_local = JSON.parse(JSON.stringify(this.data));
        this.editing = false;
      } else {
        this.$router.push({name: 'parameters'});
      }
    },
    getData(id) {
      if (id) {
        if (id == 0) {
          this.loading.hide();
          this.loaded = true;
          this.id_not_found = false;
          this.editing = true;
        } else {
          const self = this;
          const data = {
            objectId: id,
            userId: this.$store.state.fastway.userInfo.userId,
            token: this.$store.state.fastway.userInfo.token
          };
          this.$http.post(`${this.config.apiURL}/parameters/data`, data)
            .then(response => {
              if (response.data.success) {
                self.data = response.data.data;
                self.data_local = JSON.parse(JSON.stringify(response.data.data));
              } else if (id > 0) {
                self.id_not_found = true;
              }
              self.loading.hide();
              self.loaded = true;
            });
        }
      } else {
        this.loading.hide();
        this.loaded = true;
        if (id == 0) {
          this.id_not_found = false;
        }
        this.editing = true;
      }
    },
    validateForm() {
      let validated = true;
      if (!this.data_local.parameter || this.data_local.parameter == '') {
        this.$vs.notify({
          title: 'Error al guardar parámetro',
          text: 'Debe especificar un parámetro',
          color: 'danger'
        });
        validated = false;
      } else if (!this.data_local.code || this.data_local.code == '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al guardar parámetro',
            text: 'Debe especificar un código',
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        }, {timeout});
        validated = false;
      }
      return validated;
    },
    save() {
      if (this.validateForm()) {
        this.loading = this.$loading.show();
        const self = this;
        this.data_local.id = this.id;
        this.data_local.context = this.$store.state.fastway.userInfo;
        this.data_local.createdBy = this.$store.state.fastway.userInfo.userId;
        this.$http.post(`${this.api}/parameters/save`, this.data_local)
          .then(response => {
            if (response.data.success) {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Información',
                  text: 'Datos han sido actualizados',
                  icon: 'InfoIcon',
                  variant: 'success'
                }
              });
              if (self.id == 0) {
                self.$router.push({
                  name: 'parameter',
                  params: {id: response.data.data.id}
                });
              }
            } else {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error al actualizar',
                  text: response.data.message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger'
                }
              }, {timeout});
            }
            self.editing = false;
            self.loading.hide();
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error al actualizar',
                text: error.message,
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            }, {timeout});
          });
      }
    },
    confirmDeleteRecord() {
      const self = this;
      this.$bvModal.msgBoxConfirm(`Se dispone a eliminar código "${this.data.code}" de parámetro "${this.data.parameter}"`, {
        title: `Confirmar`,
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Aceptar',
        cancelTitle: 'Cancelar',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true
      })
        .then(proceed => {
          if (proceed) {
            self.loading = this.$loading.show();
            const data = {
              objectId: self.id,
              userId: self.$store.state.fastway.userInfo.userId,
              token: self.$store.state.fastway.userInfo.token
            };
            self.$http.post(`${this.api}/parameters/delete`, data)
              .then(response => {
                if (response.data.success) {
                  self.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Información eliminada',
                      text: 'Parámetro fue eliminado correctamente',
                      icon: 'InfoIcon',
                      variant: 'success'
                    }
                  });
                  self.$router.push({name: 'parameters'});
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error al eliminar',
                      text: response.data.message,
                      icon: 'AlertCircleIcon',
                      variant: 'danger'
                    }
                  }, {timeout});
                }
                this.editing = false;
                this.loading.hide();
              });
          }
        });
    }
  }
};
</script>

<style lang="scss">
@import '../../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
/*@import '../../../../node_modules/@syncfusion/ej2-calendars/styles/material.css';*/
@import '../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
/*@import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';*/
/*@import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';*/
/*@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';*/
/*@import "../../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";*/

.wrapper {
  max-width: 250px;
  margin: 0 auto;
}

.e-grid .e-altrow {
  background-color: #fafafa;
}

#avatar-col {
  width: 12rem;
}

#page-stock-branch-edit {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .4rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }
}

@media screen and (min-width: 1201px) and (max-width: 1211px),
only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }
}

</style>
