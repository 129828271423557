<template>
  <div class="flex items-center">
    <ejs-chiplist :text="text" :cssClass="color"></ejs-chiplist>
  </div>
</template>

<script>
import Vue from 'vue';
import {ChipListPlugin} from '@syncfusion/ej2-vue-buttons';

Vue.use(ChipListPlugin);

export default {
  name: 'CRStatus',
  computed: {
    text() {
      return this.$data.data.statusName;
    },
    color() {
      return (this.$data.data.statusName.substr(0, 3)
        .toUpperCase() == 'ACT') ? 'e-success' : 'e-danger';
    }
  }
};
</script>

<style>
@import '../../../node_modules/@syncfusion/ej2-vue-buttons/styles/material.css';
</style>
