<template>
  <div>
    <b-card
      border-variant="primary"
      header="Carga de archivos"
      header-bg-variant="primary"
      header-text-variant="white"
      align="center"
    >
      <b-card-text class="p-1">
        En el cuadro inferior debe colocar los archivos de texto que desea cargar.<br/>
        Puede seleccionar uno o varios archivos.<br/>
        No se procesarán archivos empacados, deben seleccionarse los archivos de texto finales.<br/>
        <br/>
        <div class="container">
          <form enctype="multipart/form-data" novalidate class="row justify-content-center">
            <div class="col"/>
            <div class="row col-7 dropbox">
              <input ref="inputFile" type="file" multiple :name="uploadFieldName" :disabled="isSaving" accept="*/*" class="input-file" @change="filesChange">
              <div v-if="isInitial" class="row w-100 align-items-center">
                <div class="col text-center">
                  Arrastre archivos a este cuadro o haga click para seleccionar
                </div>
              </div>
              <div v-else class="w-100 align-items-center p-1">
                <div v-for="file in sortedFiles" :key="file.name" class="row">
                  <b class="col-3 text-left">{{ file.name }}:</b>
                  <span class="col-9 text-left">{{ file.description }}</span>
                </div>
              </div>
            </div>
            <div class="col"/>
          </form>
        </div>
        <b-button v-if="isSuccess" class="mt-2" variant="primary" @click="loadFile">
          <feather-icon icon="UploadIcon"/>
          Cargar archivos
        </b-button>
      </b-card-text>
    </b-card>

    <b-card
      border-variant="primary"
      header="Cargas anteriores"
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <div class="row row-cols-3">
        <div v-for="(upload, index) in uploads" :key="index">
          <b-card
            :border-variant="backgroundTitle(upload)"
            :header="uploadTitle(upload)"
            :header-bg-variant="backgroundTitle(upload)"
            header-text-variant="white"
            class="m-1"
          >
            <div class="w-100 mt-1">
              Archivos cargados: {{ upload.files }}
            </div>
            <div v-for="file in upload.filesList" :key="file.name" class="row">
              <b class="col-4 text-left">{{ file.name }}:</b>
              <span class="col text-left">{{ file.description }}</span>
            </div>
            <div class="w-100 mt-1">
              Estado: <b>{{ uploadStatus(upload) }}</b>
            </div>
          </b-card>
        </div>
      </div>

    </b-card>
  </div>
</template>

<script>
import Vue from 'vue';
import {db} from '@/firebaseConfig';
import {BCard, BCardText, BButton} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const STATUS_INITIAL = 0;
const STATUS_SAVING = 1;
const STATUS_SUCCESS = 2;
const STATUS_FAILED = 3;

const timeout = Vue.$timeout1;

export default {
  components: {
    BCard,
    BCardText,
    BButton
  },
  data() {
    return {
      loading: null,
      uploadFiles: [],
      currentStatus: null,
      uploadFieldName: 'photos',
      fileTypeDescriptions: [
        {
          prefix: 'P1',
          name: 'Seguro de vida individual'
        },
        {
          prefix: 'P2',
          name: 'Seguro de vida colectivo'
        },
        {
          prefix: 'P3',
          name: 'Seguro de gastos médicos'
        },
        {
          prefix: 'P4',
          name: 'Seguro de incendio y líneas aliadas'
        },
        {
          prefix: 'P5',
          name: 'Seguro de vehículos automotores'
        },
        {
          prefix: 'P6',
          name: 'Seguro de transporte'
        },
        {
          prefix: 'P7',
          name: 'Seguro de riesgos técnicos'
        },
        {
          prefix: 'P8',
          name: 'Seguro de caución'
        },
        {
          prefix: 'P9',
          name: 'Otros seguros'
        },
        {
          prefix: 'P10',
          name: 'Seguro de accidentes personales'
        },
        {
          prefix: 'P11',
          name: 'Seguro de riesgos catastróficos'
        },
        {
          prefix: 'R1',
          name: 'Siniestros de vida individual'
        },
        {
          prefix: 'R2',
          name: 'Siniestros de vida colectivo'
        },
        {
          prefix: 'R3',
          name: 'Siniestros de gastos médicos'
        },
        {
          prefix: 'R4',
          name: 'Siniestros de incendio y líneas aliadas'
        },
        {
          prefix: 'R5',
          name: 'Siniestros de vehículos automotores'
        },
        {
          prefix: 'R6',
          name: 'Siniestros de transporte'
        },
        {
          prefix: 'R7',
          name: 'Siniestros de riesgos técnicos'
        },
        {
          prefix: 'R8',
          name: 'Siniestros de caución'
        },
        {
          prefix: 'R9',
          name: 'Siniestros de otros seguros'
        },
        {
          prefix: 'R10',
          name: 'Siniestros de accidentes personales'
        },
        {
          prefix: 'R11',
          name: 'Siniestros de riesgos catastróficos'
        }
      ]
    };
  },
  computed: {
    api() {
      return this.$store.state.fastway.parameters.apiURL;
    },
    userInfo() {
      return this.$store.state.fastway.userInfo;
    },
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    uploads() {
      return this.$store.state.fastway.uploads;
    },
    processing() {
      let process = 1;
      if (this.uploads) {
        if (this.uploads.records > this.uploads.processed || !this.uploads.finished) {
          process = 2;
        }
        if (this.uploads.records > 0 && this.uploads.processed >= this.uploads.records && this.uploads.endDate == null) {
          db.collection('uploads')
            .doc(this.userInfo.currentUpload)
            .update({endDate: new Date()});
        }
      }
      return process;
    },
    processed() {
      return this.uploads.processed > this.uploads.records ? this.uploads.records : this.uploads.processed;
    },
    sortedFiles() {
      return [...this.uploadFiles].sort((a, b) => {
        let ret = 1;
        if (a.sequence < b.sequence) {
          ret = -1;
        }
        return ret;
      });
    }
  },
  mounted() {
    this.reset();
  },
  methods: {
    reset() {
      this.uploadFiles = [];
      this.currentStatus = STATUS_INITIAL;
      if (this.$refs.inputFile) {
        this.$refs.inputFile.value = '';
      }
    },
    uploadTitle(upload) {
      return upload.startDate.toDate()
        .toLocaleString('es-GT', {timeZone: 'America/Guatemala'});
    },
    backgroundTitle(upload) {
      let color = 'success';
      if (Object.prototype.hasOwnProperty.call(upload, 'status')) {
        if (upload.status == 'Procesando') {
          color = 'warning';
        } else if (upload.status == 'Error') {
          color = 'danger';
        }
      }
      return color;
    },
    uploadStatus(upload) {
      let status = 'Finalizado';
      if (Object.prototype.hasOwnProperty.call(upload, 'status')) {
        status = upload.status;
      }
      return status;
    },
    filesChange(event) {
      if (event) event.preventDefault();
      const {files} = event.target;
      if (files !== undefined) {
        this.uploadFiles = [];
        for (let i = 0; i < files.length; i++) {
          const fileName = files[i].name;
          if (fileName.includes('.')) {
            const fileNameId = fileName.split('.')
              .shift();
            if (fileNameId.length > 7 && fileNameId.length < 10) {
              const dataDate = fileNameId.slice(-6);
              const fileType = fileNameId.replace(dataDate, '');
              const description = this.fileTypeDescriptions.find(item => item.prefix === fileType);
              if (description) {
                const fr = new FileReader();
                fr.readAsText(files[i]);
                fr.addEventListener('load', () => {
                  this.uploadFiles.push({
                    name: fileName,
                    type: fileType,
                    sequence: `${fileType.slice(0, 1)}${fileType.substring(1)
                      .padStart(3, '0')}`,
                    description: description.name,
                    lines: 1,
                    processed: 0
                  });
                  this.currentStatus = STATUS_SUCCESS;
                });
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `Error en archivo ${fileName}`,
                    text: 'Nombre de tipo de archivo no coincide con estructura SB',
                    icon: 'AlertCircleIcon',
                    variant: 'danger'
                  }
                }, {timeout});
              }
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Error en archivo ${fileName}`,
                  text: 'Nombre de archivo incorrecto',
                  icon: 'AlertCircleIcon',
                  variant: 'danger'
                }
              }, {timeout});
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Error en archivo ${fileName}`,
                text: 'Nombre de archivo no posee extensión correcta',
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            }, {timeout});
          }
        }
      } else {
        this.reset();
      }
    },
    loadFile() {
      this.loading = this.$loading.show();
      const self = this;
      const formData = new FormData();
      for (let i = 0; i < this.$refs.inputFile.files.length; i++) {
        formData.append('files', this.$refs.inputFile.files[i]);
      }
      formData.append('userId', this.userInfo.uid);
      formData.append('branchId', this.userInfo.branchId);
      db.collection('uploads')
        .add({
          userId: this.userInfo.uid,
          userName: this.userInfo.displayName,
          branchId: this.userInfo.branchId,
          token: this.userInfo.token,
          startDate: new Date(),
          endDate: null,
          files: this.$refs.inputFile.files.length,
          progress: 0,
          records: 0,
          processed: 0,
          finished: false,
          filesList: this.uploadFiles,
          status: 'Procesando'
        })
        .then(docRef => {
          formData.append('uploadId', docRef.id);
          self.$store.dispatch('fastway/updateUserInfo', {currentUpload: docRef.id});
          self.$http.post(`${self.api}/files/upload`, formData, {
            headers: {'Content-Type': 'multipart/form-data'},
            timeout: 1200000
          })
            .then(response => {
              if (response.data.success) {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Carga finalizada',
                    text: `Los archivos fueron cargados, espere confirmación de validación`,
                    icon: 'InfoIcon',
                    variant: 'success'
                  }
                }, {timeout});
                self.reset();
              } else {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error al cargar archivo',
                    text: response.data.message,
                    icon: 'AlertCircleIcon',
                    variant: 'danger'
                  }
                }, {timeout});
              }
              self.loading.hide();
            })
            .catch(error => {
              self.loading.hide();
              let errorMessage = '';
              if (error.message) {
                errorMessage = error.message;
              } else if (error.response) {
                errorMessage = error.response.data.ErrorMessage;
              } else {
                errorMessage = 'Error de conectividad';
              }
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error al cargar archivo',
                  text: errorMessage,
                  icon: 'AlertCircleIcon',
                  variant: 'danger'
                }
              }, {timeout});
            });
        })
        .catch(error => {
          self.loading.hide();
          self.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error al cargar archivo',
              text: error.message,
              icon: 'AlertCircleIcon',
              variant: 'danger'
            }
          }, {timeout});
        });
    },
    uploadProgress(fileUpload) {
      let percent = 0;
      if (fileUpload.lines > 0 && fileUpload.processed > 0) {
        percent = Math.round(fileUpload.processed / fileUpload.lines * 100);
      }
      return percent;
    }
  }
};
</script>
<style>
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  width: 700px;
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
  z-index: 100;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
