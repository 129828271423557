<!-- =========================================================================================
  File Name: Branch.vue
  Description: Branch value Edit Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>

    <b-alert warning="danger" :active.sync="id_not_found">
      <h4 class="alert-heading">
        Empresa no encontrada
      </h4>
      <div class="alert-body">
        Empresa con id {{ $route.params.id }} no encontrada.
        <span>
          <span>Verifique </span><router-link :to="{name:'branches'}" class="text-inherit underline"> todas las empresas</router-link>
        </span>
      </div>
    </b-alert>

    <b-card v-show="loaded" class="mb-2">
      <b-container class="px-6 pt-6">
        <div class="d-flex justify-content-between mb-2">
          <div v-if="editing" class="d-flex flex-row w-25">
            <!--            <ejs-textbox v-model="data_local.code" class="e-input" cssClass="e-code" type="text" floatLabelType="Auto" placeholder="Código" :enabled="editing"/>-->
            <b-form-input v-model="data_local.code" size="lg" placeholder="Escriba aquí el código" :plaintext="!editing" :state="data_local.code.length > 0" required trim/>
          </div>
          <div v-else class="d-flex flex-row w-25 bg-light rounded-sm px-1">
            <div class="flex flex-row">
              <span class="small text-black-50">Código</span>
              <h3 class="font-weight-bolder">{{ data_local.code }}</h3>
            </div>
          </div>
          <span class="flex-grow-1"></span>
          <div v-if="editing" class="d-flex flex-row bg-primary rounded-sm text-white cursor-pointer p-1 justify-content-between" style="width: 200px; margin-right: 1px;" @click="save">
            <feather-icon icon="SaveIcon" size="20"/>
            <div>Guardar cambios</div>
            <div/>
          </div>
          <div v-if="editing" class="d-flex flex-row border border-warning rounded-sm cursor-pointer p-1 justify-content-between" style="width: 150px;" @click="reset_data">
            <feather-icon icon="CornerUpLeftIcon" size="20"/>
            <div>Cancelar</div>
            <div/>
          </div>
          <div v-if="!editing" class="d-flex flex-row bg-primary rounded-sm text-white cursor-pointer p-1 justify-content-between" style="width: 150px; margin-right: 1px;" @click="editing = true">
            <feather-icon icon="Edit2Icon" size="20"/>
            <div>Editar</div>
            <div/>
          </div>
          <div v-if="!editing" class="d-flex flex-row bg-danger rounded-sm text-white cursor-pointer p-1 justify-content-between" style="width: 150px;" @click="confirmDeleteRecord">
            <feather-icon icon="XIcon" size="20"/>
            <div>Eliminar</div>
            <div/>
          </div>
        </div>
        <b-row>
          <b-col>
            <ejs-textbox v-model="data_local.name" class="e-input" type="text" floatLabelType="Auto" placeholder="Nombre" :enabled="editing"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <ejs-textbox v-model="data_local.suffix" class="e-input" type="text" floatLabelType="Auto" placeholder="Sufijo" :enabled="editing"/>
          </b-col>
          <b-col>
            <ejs-dropdownlist v-model="data_local.statusId" floatLabelType="Auto" placeholder="Estado" highlight=true :dataSource="status" :fields="statusFields" :enabled="editing"></ejs-dropdownlist>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <ejs-textbox v-model="data_local.comments" name="comments" :multiline="true" floatLabelType="Auto" placeholder="Comentarios" :enabled="editing"/>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import Vue from "vue";
import {BCard, BAlert, BContainer, BRow, BCol, BFormInput} from 'bootstrap-vue';
import {TextBoxPlugin} from '@syncfusion/ej2-vue-inputs';
import {DropDownListPlugin} from "@syncfusion/ej2-vue-dropdowns";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

Vue.use(TextBoxPlugin);
Vue.use(DropDownListPlugin);

const timeout = Vue.$timeout1;

export default {
  components: {
    BCard,
    BAlert,
    BContainer,
    BRow,
    BCol,
    BFormInput
  },
  data() {
    return {
      id: 0,
      loading: null,
      loaded: false,
      editing: false,
      status: [],
      data: null,
      statusFields: {
        text: 'value',
        value: 'code'
      },
      data_local: {
        id: 0,
        code: '',
        name: '',
        comments: '',
        statusId: 'IN',
        statusName: ''
      },
      id_not_found: false
    };
  },
  computed: {
    config() {
      return this.$store.state.fastway.parameters;
    },
    api() {
      return this.$store.state.fastway.parameters.apiURL;
    }
  },
  mounted() {
    const self = this;
    this.id = parseInt(this.$route.params.id, 10);
    this.loading = this.$loading.show();
    this.$http
      .post(`${this.api}/lists/get_list`, {
        userId: this.$store.state.fastway.userInfo.userId,
        token: this.$store.state.fastway.userInfo.token,
        list: 'ESTADOEMPRESA'
      })
      .then(response => {
        if (response.data.success) {
          self.status = response.data.data;
        }
        self.getData(self.id);
      });
  },
  methods: {
    reset_data() {
      if (this.id > 0) {
        this.data_local = JSON.parse(JSON.stringify(this.data));
        this.editing = false;
      } else {
        this.$router.push({name: 'branches'});
      }
    },
    getData(id) {
      if (id) {
        if (id == 0) {
          this.loading.hide();
          this.loaded = true;
          this.id_not_found = false;
          this.editing = true;
        } else {
          const self = this;
          this.$http.post(`${this.config.apiURL}/infra/branches/data`, {
            id,
            context: this.$store.state.fastway.userInfo
          })
            .then(response => {
              if (response.data.success) {
                self.data = response.data.data;
                self.data_local = JSON.parse(JSON.stringify(response.data.data));
              } else if (id > 0) {
                self.id_not_found = true;
              }
              self.loading.hide();
              self.loaded = true;
            });
        }
      } else {
        this.loading.hide();
        this.loaded = true;
        if (id == 0) {
          this.id_not_found = false;
        }
        this.editing = true;
      }
    },
    validateForm() {
      let message = '';
      if (!this.data_local.code || this.data_local.code == '') {
        message = 'Debe especificar un código';
      } else if (!this.data_local.name || this.data_local.name == '') {
        message = 'Debe especificar un nombre';
      } else if (!this.data_local.statusId || this.data_local.statusId == '') {
        message = 'Debe seleccionar un estado';
      } else {
        const status = this.status.filter(item => item.code == this.data_local.statusId);
        if (status.length <= 0) {
          message = 'Estado incorrecto';
        }
      }
      if (message != '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al guardar empresa',
            text: 'Debe especificar una descripción',
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        }, {timeout});
      }
      return (message == '');
    },
    save() {
      if (this.validateForm()) {
        this.loading = this.$loading.show();
        const self = this;
        this.data_local.id = this.id;
        this.data_local.context = this.$store.state.fastway.userInfo;
        this.data_local.createdBy = this.$store.state.fastway.userInfo.userId;
        this.$http.post(`${this.api}/infra/branches/save`, this.data_local)
          .then(response => {
            if (response.data.success) {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Información',
                  text: 'Datos han sido actualizados',
                  icon: 'InfoIcon',
                  variant: 'success'
                }
              });
              if (self.id == 0) {
                self.$router.push({
                  name: 'branch',
                  params: {id: response.data.data.id}
                });
              }
            } else {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error al actualizar',
                  text: response.data.message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger'
                }
              }, {timeout});
            }
            self.editing = false;
            self.loading.hide();
          })
          .catch(error => {
            self.loading.hide();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error al actualizar',
                text: error.message,
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            }, {timeout});
          });
      }
    },
    confirmDeleteRecord() {
      const self = this;
      this.$bvModal.msgBoxConfirm(`Se dispone a eliminar código "${this.data.code}" de empresa "${this.data.branch}"`, {
        title: `Confirmar`,
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Aceptar',
        cancelTitle: 'Cancelar',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true
      })
        .then(proceed => {
          if (proceed) {
            self.loading = this.$loading.show();
            const data = {
              objectId: self.id,
              userId: self.$store.state.fastway.userInfo.userId,
              token: self.$store.state.fastway.userInfo.token
            };
            self.$http.post(`${this.api}/branches/deletee`, data)
              .then(response => {
                if (response.data.success) {
                  self.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Información eliminada',
                      text: 'Empresa fue eliminada correctamente',
                      icon: 'InfoIcon',
                      variant: 'success'
                    }
                  });
                  self.$router.push({name: 'branches'});
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error al eliminar',
                      text: response.data.message,
                      icon: 'AlertCircleIcon',
                      variant: 'danger'
                    }
                  }, {timeout});
                }
                this.editing = false;
                this.loading.hide();
              });
          }
        });
    }
  }
};
</script>

<style lang="scss">
@import '../../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
/*@import '../../../../node_modules/@syncfusion/ej2-calendars/styles/material.css';*/
@import '../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
/*@import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';*/
/*@import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';*/
/*@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';*/
/*@import "../../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";*/

.e.input .e-code {
  font-weight: bolder;
  font-size: large;
}

.wrapper {
  max-width: 250px;
  margin: 0 auto;
}

.e-grid .e-altrow {
  background-color: #fafafa;
}

#avatar-col {
  width: 12rem;
}

#page-stock-branch-edit {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .4rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }
}

@media screen and (min-width: 1201px) and (max-width: 1211px),
only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }
}

</style>
