import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Login from '@/views/Login';
import Error404 from '@/views/error/Error404';
import Home from '@/views/Home';
import Profile from '@/views/infra/users/Profile';
import Parameters from '@/views/infra/parameters/Parameters';
import Parameter from '@/views/infra/parameters/Parameter';
import Lists from '@/views/infra/lists/Lists';
import List from '@/views/infra/lists/List';
import Roles from '@/views/infra/roles/Roles';
import Role from '@/views/infra/roles/Role';
import Users from '@/views/infra/users/Users';
import User from '@/views/infra/users/User';
import Branches from '@/views/infra/branches/Branches';
import Branch from '@/views/infra/branches/Branch';
import StatisticMedical from '@/views/statistics/medical/Medical';
import StatisticVehicles from '@/views/statistics/vehicles/Vehicles';
import StatisticCOVID from '@/views/statistics/covid/COVID';
import Upload from '@/views/Upload';
import ReportUpload from '@/views/reports/ReportUpload';
import ReportsList from '@/views/reports/ReportsList';
import UploadedFilesList from '@/views/reports/UploadedFilesList';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  },
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: Error404,
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        pageTitle: 'Inicio',
        breadcrumb: [
          {
            text: 'Inicio',
            active: true
          }
        ]
      }
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: {
        pageTitle: 'Perfil de usuario',
        breadcrumb: [
          {
            text: 'Perfil',
            active: true
          }
        ]
      }
    },
    //
    // INFRAESTRUCTURA.
    //
    {
      path: '/infra/parameters',
      name: 'parameters',
      component: Parameters,
      meta: {
        pageTitle: 'Parámetros',
        breadcrumb: [
          {
            text: 'Parámetros',
            active: true
          }
        ]
      }
    },
    {
      path: '/infra/parameter/:id',
      name: 'parameter',
      component: Parameter,
      meta: {
        pageTitle: 'Parámetro',
        breadcrumb: [
          {
            text: 'Parámetros',
            to: {name: 'parameters'}
          },
          {
            text: 'Parámetro',
            active: true
          }
        ]
      }
    },
    {
      path: '/infra/lists',
      name: 'lists',
      component: Lists,
      meta: {
        pageTitle: 'Listas',
        breadcrumb: [
          {
            text: 'Listas',
            active: true
          }
        ]
      }
    },
    {
      path: '/infra/list/:id',
      name: 'list',
      component: List,
      meta: {
        pageTitle: 'Valor de lista',
        breadcrumb: [
          {
            text: 'Listas',
            to: {name: 'lists'}
          },
          {
            text: 'Valor de lista',
            active: true
          }
        ]
      }
    },
    {
      path: '/infra/roles',
      name: 'roles',
      component: Roles,
      meta: {
        pageTitle: 'Roles',
        breadcrumb: [
          {
            text: 'Roles',
            active: true
          }
        ]
      }
    },
    {
      path: '/infra/role/:id',
      name: 'role',
      component: Role,
      meta: {
        pageTitle: 'Rol',
        breadcrumb: [
          {
            text: 'Roles',
            to: {name: 'roles'}
          },
          {
            text: 'Rol',
            active: true
          }
        ]
      }
    },
    {
      path: '/infra/users',
      name: 'users',
      component: Users,
      meta: {
        pageTitle: 'Usuarios',
        breadcrumb: [
          {
            text: 'Usuarios',
            active: true
          }
        ]
      }
    },
    {
      path: '/infra/user/:id',
      name: 'user',
      component: User,
      meta: {
        pageTitle: 'Usuario',
        breadcrumb: [
          {
            text: 'Usuarios',
            to: {name: 'users'}
          },
          {
            text: 'Usuario',
            active: true
          }
        ]
      }
    },
    {
      path: '/infra/branches',
      name: 'branches',
      component: Branches,
      meta: {
        pageTitle: 'Empresas',
        breadcrumb: [
          {
            text: 'Empresas',
            active: true
          }
        ]
      }
    },
    {
      path: '/infra/branch/:id',
      name: 'branch',
      component: Branch,
      meta: {
        pageTitle: 'Empresa',
        breadcrumb: [
          {
            text: 'Empresas',
            to: {name: 'branches'}
          },
          {
            text: 'Empresa',
            active: true
          }
        ]
      }
    },
    {
      path: '/statistics/medical',
      name: 'statisticsMedical',
      component: StatisticMedical,
      meta: {
        pageTitle: 'Estadísticas de Gastos Médicos',
        breadcrumb: [
          {
            text: 'Estadísticas de Gastos Médicos',
            active: true
          }
        ]
      }
    },
    {
      path: '/statistics/vehicles',
      name: 'statisticsVehicles',
      component: StatisticVehicles,
      meta: {
        pageTitle: 'Estadísticas de Vehículos',
        breadcrumb: [
          {
            text: 'Estadísticas de Vehículos',
            active: true
          }
        ]
      }
    },
    {
      path: '/statistics/covid',
      name: 'statisticsCOVID',
      component: StatisticCOVID,
      meta: {
        pageTitle: 'COVID-19',
        breadcrumb: [
          {
            text: 'COVID-19',
            active: true
          }
        ]
      }
    },
    {
      path: '/reports/uploadedFiles',
      name: 'uploadedFilesList',
      component: UploadedFilesList,
      meta: {
        pageTitle: 'Archivos cargados',
        breadcrumb: [
          {
            text: 'Archivos cargados',
            active: true
          }
        ]
      }
    },
    {
      path: '/reports/upload',
      name: 'reportUpload',
      component: ReportUpload,
      meta: {
        pageTitle: 'Carga de informe',
        breadcrumb: [
          {
            text: 'Carga de informe',
            active: true
          }
        ]
      }
    },
    {
      path: '/reports/list',
      name: 'reportsList',
      component: ReportsList,
      meta: {
        pageTitle: 'Informes disponibles',
        breadcrumb: [
          {
            text: 'Informes disponibles',
            active: true
          }
        ]
      }
    },
    {
      path: '/uploads',
      name: 'uploads',
      component: Upload,
      meta: {
        pageTitle: 'Carga de archivos',
        breadcrumb: [
          {
            text: 'Carga de archivos',
            active: true
          }
        ]
      }
    },
    {
      path: '*',
      redirect: 'error-404'
    }
  ]
});

//
// Autenticación interna
//
router.beforeEach((to, from, next) => {
  if (to.name == 'login') {
    next();
  } else if (store.state.fastway.userInfo.token) {
    if (store.state.fastway.session) {
      if (store.state.fastway.session.token == store.state.fastway.userInfo.token) {
        next();
      } else {
        next({name: 'login'});
      }
    } else {
      store.dispatch('fastway/bindSession', store.state.fastway.userInfo.token)
        .then(data => {
          let toNext = false;
          if (data) {
            toNext = (data.token == store.state.fastway.userInfo.token);
          }
          if (toNext) {
            next();
          } else {
            next({name: 'login'});
          }
        });
    }
  } else if (to.name == 'login') {
    next();
  } else {
    next({name: 'login'});
  }

  /*
      db.collection('sessions').doc(store.state.fastway.userInfo.token).get().then(snapshot => {
        if (snapshot.exists) {
          next();
        } else {
          next({name: 'login'});
        }
      });
  */
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
