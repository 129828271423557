import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import {vuexfireMutations} from 'vuexfire';

// Modules
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import fastway from './fastway';

Vue.use(Vuex);

const dataState = createPersistedState({
  paths: ['fastway.parameters', 'fastway.userInfo', 'fastway.navMenuItems']
});

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    fastway
  },
  mutations: {
    ...vuexfireMutations
  },
  plugins: [dataState],
  strict: process.env.DEV
});
