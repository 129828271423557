<!-- =========================================================================================
  File Name: UploadedFilesList.vue
  Description: Uploaded files list
  ----------------------------------------------------------------------------------------
  Item Name: FastWay - Uploaded files list
  Author: Oscar Ramírez O.
  Author URL: https://acentoNET.com
========================================================================================== -->

<template>

  <div>
    <b-card class="p-6">
      <ejs-grid
        v-if="loaded"
        ref="grid"
        :dataSource="data"
        locale='es-GT'
        :toolbar='toolbarOptions'
        :allowPaging="false"
        :pageSettings='pageSettings'
        :allowSorting='true'
        :sortSettings='sortOptions'
        :allowExcelExport='true'
        :toolbarClick='toolbarClick'
        :allowFiltering='true'
        :filterSettings='filterOptions'
      >
        <e-columns>
          <e-column field="branch" headerText="Aseguradora" width="200" :filter='checkBoxFilter'/>
          <e-column field='type' headerText='Tipo' :filter='checkBoxFilter'/>
          <e-column field="sourceFile" headerText="Archivo" width="150" :filter='checkBoxFilter'/>
          <e-column field="sizeInBytes" headerText="Tamaño" width="150" :filter='checkBoxFilter'/>
          <e-column field="year" headerText="Año" width="110" :filter='checkBoxFilter'/>
          <e-column field="month" headerText="Mes" width="110" :filter='checkBoxFilter'/>
          <e-column field="day" headerText="Día" width="100" :filter='checkBoxFilter'/>
          <e-column field="code" headerText="Código" width="120" :filter='checkBoxFilter'/>
          <e-column field="uploadDate" headerText="Fecha carga" width="180" :valueAccessor="formatDate"/>
          <e-column field="lines" headerText="Líneas" width="100" type="number" textAlign='Right' format="N0" :allowFiltering='false'/>
        </e-columns>
      </ejs-grid>
    </b-card>
  </div>

</template>

<script>
import Vue from 'vue';
import {BCard} from 'bootstrap-vue';
import {loadCldr, L10n, setCulture} from '@syncfusion/ej2-base';
import {GridPlugin, Toolbar, Search, Page, Sort, ExcelExport, Filter} from '@syncfusion/ej2-vue-grids';
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import gregorian from 'cldr-data/main/es-GT/ca-gregorian.json';
import numbers from 'cldr-data/main/es-GT/numbers.json';
import timeZoneNames from 'cldr-data/main/es-GT/timeZoneNames.json';
import weekData from 'cldr-data/supplemental/weekData.json';

Vue.use(GridPlugin);

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
setCulture('es-GT');
L10n.load({
  'es-GT': {
    grid: {
      EmptyRecord: 'No se encontraron datos',
      Search: 'Buscar',
      Excelexport: 'Exportar'
    },
    pager: {
      currentPageInfo: '{0} de {1} páginas',
      firstPageTooltip: 'Ir a primera página',
      lastPageTooltip: 'Ir a última página',
      nextPageTooltip: 'Ir a página siguiente',
      previousPageTooltip: 'Ir a página anterior'
    }
  }
});

export default {
  components: {
    BCard
  },
  data() {
    return {
      pageSettings: {pageSize: 12},
      toolbarOptions: [{
        tooltipText: 'Recargar',
        prefixIcon: 'e-refresh',
        id: 'reloadData'
      }, 'ExcelExport', 'Search'],
      sortOptions: {
        columns: [{
          field: 'type',
          direction: 'Ascending'
        }]
      },
      loaded: false,
      data: [],
      filterOptions: {
        type: 'Menu'
      },
      checkBoxFilter: {
        type: 'CheckBox'
      }
    };
  },
  provide: {
    grid: [Toolbar, Search, Page, Sort, ExcelExport, Filter]
  },
  computed: {
    api() {
      return this.$store.state.fastway.parameters.apiURL;
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    formatDate(field, data) {
      const d = new Date(data[field].replace('Z', ''));
      return d.toLocaleString();
      // return `${d.getDate().toString().padStart(2, '0')}/${d.getMonth().toString().padStart(2, '0')}/${d.getFullYear()} ${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}:${d.getSeconds().toString().padStart(2, '0')}`;
    },
    toolbarClick(args) {
      if (args.item.id.includes('excelexport')) {
        const excelExportProperties = {
          fileName: "ListadoArchivosCargados.xlsx"
        };
        this.$refs.grid.excelExport(excelExportProperties);
      } else if (args.item.id == 'reloadData') {
        this.getData();
      }
    },
    getData() {
      const loading = this.$loading.show();
      const self = this;
      this.$http.post(`${this.api}/files/list`, this.$store.state.fastway.userInfo)
        .then(response => {
          if (response.data.success) {
            if (response.data.data) {
              self.data = response.data.data;
            }
          }
          // this.$vs.loading.close();
          loading.hide();
          self.loaded = true;
        })
        .catch(() => {
          self.loaded = true;
          loading.hide();
          // let errorMessage = '';
          // if (error.response) {
          //   errorMessage = error.response.data.ErrorMessage
          // } else {
          //   errorMessage = 'Error de conectividad'
          // }
          // self.$vs.notify({
          //   title: 'Error al cargar listado de parámetros',
          //   text: errorMessage,
          //   color: 'danger'
          // });
        });
    }
  }
};

</script>

<style lang="scss">
@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import "../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';

.e-toolbar-item .e-refresh::before {
  font: normal normal normal 16px/1 FontAwesome;
  content: "\f021";
  color: green;
}
</style>
