<template>
  <div class="flex items-center">
    <div style="cursor: pointer; text-underline-style: single;" @click="route">
      {{ value }}
    </div>
  </div>
</template>

<script>
import router from '@/router';

export default {
  name: 'CellRendererLink',
  computed: {
    value() {
      return this.$data.data[this.$data.data.column.displayValue];
    },
    url() {
      return `${this.$data.data.column.urlBase}`;
    },
    routeName() {
      return `${this.$data.data.column.routeName}`;
    }
  },
  methods: {
    route() {
      router.push({
        name: this.routeName,
        params: {id: this.$data.data.id}
      });
    }
  }
};
</script>
