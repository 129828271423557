<!-- =========================================================================================
  File Name: User.vue
  Description: User Edit Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>

    <b-alert warning="danger" :active.sync="id_not_found">
      <h4 class="alert-heading">
        Usuario no encontrado
      </h4>
      <div class="alert-body">
        Usuario con id {{ $route.params.id }} no encontrado.
        <span>
          <span>Verifique </span><router-link :to="{name:'users'}" class="text-inherit underline"> todos los usuarios</router-link>
        </span>
      </div>
    </b-alert>

    <b-card v-if="loaded" class="mb-2">
      <b-container fluid class="px-6 pt-6">
        <div class="d-flex justify-content-between mb-2">
          <div v-if="editing" class="d-flex flex-row w-25">
            <b-form-input v-model="data_local.user" size="lg" placeholder="Escriba aquí el código" :plaintext="!editing" :state="data_local.user.length > 0" required trim/>
          </div>
          <div v-else class="d-flex flex-row w-25 bg-light rounded-sm px-1">
            <div class="flex flex-row">
              <span class="small text-black-50">Código</span>
              <h3 class="font-weight-bolder">{{ data_local.user }}</h3>
            </div>
          </div>
          <span class="flex-grow-1"></span>
          <div v-if="editing" class="d-flex flex-row bg-primary rounded-sm text-white cursor-pointer p-1 justify-content-between" style="width: 200px; margin-right: 1px;" @click="save">
            <feather-icon icon="SaveIcon" size="20"/>
            <div>Guardar cambios</div>
            <div/>
          </div>
          <div v-if="editing" class="d-flex flex-row border border-warning rounded-sm cursor-pointer p-1 justify-content-between" style="width: 150px;" @click="reset_data">
            <feather-icon icon="CornerUpLeftIcon" size="20"/>
            <div>Cancelar</div>
            <div/>
          </div>
          <div v-if="!editing" class="d-flex flex-row bg-success rounded-sm text-white cursor-pointer p-1 justify-content-between" style="width: 170px; margin-right: 1px;" @click="$refs.password.show()">
            <feather-icon icon="KeyIcon" size="20"/>
            <div>Cambiar clave</div>
            <div/>
          </div>
          <div v-if="!editing" class="d-flex flex-row bg-primary rounded-sm text-white cursor-pointer p-1 justify-content-between" style="width: 150px; margin-right: 1px;" @click="editing = true">
            <feather-icon icon="Edit2Icon" size="20"/>
            <div>Editar</div>
            <div/>
          </div>
          <div v-if="!editing" class="d-flex flex-row bg-danger rounded-sm text-white cursor-pointer p-1 justify-content-between" style="width: 150px;" @click="confirmDeleteRecord">
            <feather-icon icon="XIcon" size="20"/>
            <div>Eliminar</div>
            <div/>
          </div>
        </div>
        <b-row class="mb-1">
          <b-col>
            <ejs-textbox v-model="data_local.name" class="e-input" type="text" floatLabelType="Auto" placeholder="Nombre" :enabled="editing"/>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col>
            <ejs-textbox v-model="data_local.email" class="e-input" type="email" floatLabelType="Auto" placeholder="Correo electrónico" :enabled="editing"/>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col>
            <ejs-dropdownlist
              ref="branch"
              v-model="data_local.branchId"
              floatLabelType="Auto"
              placeholder="Empresa"
              highlight=true
              :dataSource="branches"
              :fields="{text: 'name', value: 'id'}"
              :enabled="editing"
              :query='branchQuery'
              allowFiltering=true
              :filtering='branchFiltering'
            />
          </b-col>
          <b-col>
            <ejs-dropdownlist v-model="data_local.statusId" floatLabelType="Auto" placeholder="Estado" highlight=true :dataSource="status" :fields="statusFields" :enabled="editing"></ejs-dropdownlist>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <ejs-multiselect ref="roles" v-model="data_local.rolList" floatLabelType="Auto" placeholder="Roles asignados" :dataSource="roles" mode="Box" :fields="{text: 'searchField', value: 'id'}" :enabled="editing"></ejs-multiselect>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <b-modal
      ref="password"
      :title="`Cambio de clave para usuario ${data_local.user}`"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Especifique la clave para el usuario"
          label-for="password-input"
          invalid-feedback="Debe especificar una clave de por lo menos 6 caracteres"
          :state="passwordState"
        >
          <b-form-input
            id="password-input"
            v-model="password"
            :state="passwordState"
            type="password"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Confirme clave"
          label-for="confirm-input"
          :invalid-feedback="confirmFeedback"
          :state="confirmState"
        >
          <b-form-input
            id="confirm-input"
            v-model="confirm"
            :state="confirmState"
            type="password"
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import {BCard, BAlert, BContainer, BRow, BCol, BModal, BFormGroup, BFormInput} from 'bootstrap-vue';
import {TextBoxPlugin} from '@syncfusion/ej2-vue-inputs';
import {MultiSelectPlugin} from "@syncfusion/ej2-vue-dropdowns";
import {Query} from "@syncfusion/ej2-data";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

Vue.use(TextBoxPlugin);
Vue.use(MultiSelectPlugin);

const timeout = Vue.$timeout1;

export default {
  components: {
    BCard,
    BAlert,
    BContainer,
    BRow,
    BCol,
    BModal,
    BFormGroup,
    BFormInput
  },
  data() {
    return {
      id: 0,
      loading: null,
      loaded: false,
      editing: false,
      status: [],
      branches: [{name: ', id: 0'}],
      roles: [],
      branchQuery: new Query().select(['name', 'id']).take(10),
      data: null,
      statusFields: {
        text: 'value',
        value: 'code'
      },
      data_local: {
        id: 0,
        user: '',
        name: '',
        email: '',
        statusId: 'IN',
        branchId: 0,
        rolList: []
      },
      password: '',
      confirm: '',
      id_not_found: false
    };
  },
  computed: {
    config() {
      return this.$store.state.fastway.parameters;
    },
    api() {
      return this.$store.state.fastway.parameters.apiURL;
    },
    passwordState() {
      return (this.password.length > 5);
    },
    confirmState() {
      return (this.confirm.length > 5 && this.confirm == this.password);
    },
    confirmFeedback() {
      return (this.confirm == this.password ? '' : 'Clave y confirmación no coinciden');
    }
  },
  mounted() {
    const self = this;
    this.id = parseInt(this.$route.params.id, 10);
    this.loading = this.$loading.show();
    this.$http
      .post(`${this.api}/lists/get_list`, {
        userId: this.$store.state.fastway.userInfo.userId,
        token: this.$store.state.fastway.userInfo.token,
        list: 'ESTADOUSUARIO'
      })
      .then(response => {
        if (response.data.success) {
          self.status = response.data.data;
        }
        return self.$http.post(`${this.api}/infra/branches/list`, self.$store.state.fastway.userInfo);
      })
      .then(responseB => {
        if (responseB.data.success) {
          self.branches = responseB.data.data;
        }
        return self.$http.post(`${this.api}/roles/list`, self.$store.state.fastway.userInfo);
      })
      .then(responseR => {
        if (responseR.data.success) {
          self.roles = responseR.data.data;
        }
        self.getData(self.id);
      })
      .catch(error => {
        let errorMessage = '';
        if (error.message) {
          errorMessage = error.message;
        } else if (error.response) {
          errorMessage = error.response.data.ErrorMessage;
        } else {
          errorMessage = 'Error de conectividad';
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al cargar datos',
            text: errorMessage,
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        }, {timeout});
      });
  },
  methods: {
    reset_data() {
      if (this.id > 0) {
        this.data_local = JSON.parse(JSON.stringify(this.data));
        this.editing = false;
      } else {
        this.$router.push({name: 'users'});
      }
    },
    branchFiltering(e) {
      const searchData = JSON.parse(JSON.stringify(this.branches));
      if (e.text == '') {
        e.updateData(searchData);
      } else {
        // var query = new Query().select(['name', 'id']);
        let query = new Query().select(['name', 'id']);
        query = (e.text !== '') ? query.where('name', 'contains', e.text, true, true) : query;
        e.updateData(searchData, query);
      }
    },
    checkFormValidity() {
      let valid = this.$refs.form.checkValidity();
      if (valid) {
        if (this.password != this.confirm) {
          valid = false;
        }
      }
      return valid;
    },
    resetModal() {
      this.password = '';
      this.confirm = '';
      this.passwordState = null;
      this.confirmState = null;
      this.confirmFeedback = '';
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (this.checkFormValidity()) {
        const self = this;
        this.loading = this.$loading.show();
        this.data_local.id = this.id;
        this.data_local.password = this.password;
        this.data_local.context = this.$store.state.fastway.userInfo;
        this.data_local.createdBy = this.$store.state.fastway.userInfo.userId;
        this.$http.post(`${this.api}/users/change_password`, this.data_local)
          .then(response => {
            if (response.data.success) {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Información',
                  text: 'Clave actualizada',
                  icon: 'InfoIcon',
                  variant: 'success'
                }
              });
            } else {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error al actualizar',
                  text: response.data.message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger'
                }
              }, {timeout});
            }
            self.resetModal();
            self.loading.hide();
            self.$refs.password.hide();
          })
          .catch(error => {
            self.resetModal();
            self.loading.hide();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error al actualizar',
                text: error.message,
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            }, {timeout});
          });
      }
    },
    getData(id) {
      if (id) {
        if (id == 0) {
          this.loading.hide();
          this.loaded = true;
          this.id_not_found = false;
          this.editing = true;
        } else {
          const self = this;
          this.$http.post(`${this.config.apiURL}/users/data`, {
            id,
            context: this.$store.state.fastway.userInfo
          })
            .then(response => {
              if (response.data.success) {
                self.data = response.data.data;
                self.data_local = JSON.parse(JSON.stringify(response.data.data));
              } else if (id > 0) {
                self.id_not_found = true;
              }
              self.loading.hide();
              self.loaded = true;
            });
        }
      } else {
        this.loading.hide();
        this.loaded = true;
        if (id == 0) {
          this.id_not_found = false;
        }
        this.editing = true;
      }
    },
    validateForm() {
      let message = '';
      if (!this.data_local.user || this.data_local.user == '') {
        message = 'Debe especificar un código';
      } else if (!this.data_local.name || this.data_local.name == '') {
        message = 'Debe especificar un nombre del usuario';
      } else if (!this.data_local.rolList || this.data_local.rolList.length <= 0) {
        message = 'Debe indicar por lo menos un rol para el usuario';
      }
      if (message != '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al guardar usuario',
            text: message,
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        }, {timeout});
      }
      return (message == '');
    },
    save() {
      if (this.validateForm()) {
        this.loading = this.$loading.show();
        const self = this;
        this.data_local.id = this.id;
        this.data_local.context = this.$store.state.fastway.userInfo;
        this.data_local.createdBy = this.$store.state.fastway.userInfo.userId;
        this.$http.post(`${this.api}/users/save`, this.data_local)
          .then(response => {
            if (response.data.success) {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Información',
                  text: 'Datos han sido actualizados',
                  icon: 'InfoIcon',
                  variant: 'success'
                }
              });
              if (self.id == 0) {
                self.$router.push({
                  name: 'user',
                  params: {id: response.data.data.id}
                });
              }
            } else {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error al actualizar',
                  text: response.data.message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger'
                }
              }, {timeout});
            }
            self.editing = false;
            self.loading.hide();
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error al actualizar',
                text: error.message,
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            }, {timeout});
          });
      }
    },
    confirmDeleteRecord() {
      const self = this;
      this.$bvModal.msgBoxConfirm(`Se dispone a eliminar rol "${this.data.user}"`, {
        title: `Confirmar`,
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Aceptar',
        cancelTitle: 'Cancelar',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true
      })
        .then(proceed => {
          if (proceed) {
            self.loading = this.$loading.show();
            const data = {
              objectId: self.id,
              userId: self.$store.state.fastway.userInfo.userId,
              token: self.$store.state.fastway.userInfo.token
            };
            self.$http.post(`${this.api}/users/delete`, data)
              .then(response => {
                if (response.data.success) {
                  self.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Información eliminada',
                      text: 'Usuario fue eliminado correctamente',
                      icon: 'InfoIcon',
                      variant: 'success'
                    }
                  });
                  self.$router.push({name: 'users'});
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error al eliminar',
                      text: response.data.message,
                      icon: 'AlertCircleIcon',
                      variant: 'danger'
                    }
                  }, {timeout});
                }
                this.editing = false;
                this.loading.hide();
              });
          }
        });
    }
  }
};
</script>

<style lang="scss">
@import '../../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
/*@import '../../../../node_modules/@syncfusion/ej2-calendars/styles/material.css';*/
@import '../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
/*@import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';*/
/*@import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';*/
/*@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';*/
/*@import "../../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";*/

.e.input .e-code {
  font-weight: bolder;
  font-size: large;
}

.wrapper {
  max-width: 250px;
  margin: 0 auto;
}

.e-grid .e-altrow {
  background-color: #fafafa;
}

#avatar-col {
  width: 12rem;
}

#page-stock-user-edit {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .4rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }
}

@media screen and (min-width: 1201px) and (max-width: 1211px),
only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }
}

</style>
